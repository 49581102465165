import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { Container } from 'react-bootstrap';

function TodaysData() {
  const [todaysData, setTodaysData] = useState([]);

  useEffect(() => {
    const fetchTodaysData = async () => {
      try {
        // Get today's start and end
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        // Query Firestore for today's gate passes
        const snapshot = await db.collection("gatePasses")
          .where("date", ">=", today)
          .where("date", "<", tomorrow)
          .orderBy("date", "desc")
          .get();

        const data = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          date: doc.data().date.toDate().toISOString()
        }));

        setTodaysData(data);
      } catch (error) {
        console.error("Error fetching today's data:", error);
      }
    };

    fetchTodaysData();
  }, []);

  return (
    <Container fluid className="p-3">
      <pre className="bg-light p-3 rounded" id='todays-gatepassdata'>
        {JSON.stringify(todaysData, null, 2)}
      </pre>
    </Container>
  );
}

export default TodaysData;