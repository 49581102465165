import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Table, Container, Row, Col } from "react-bootstrap";
import GatePassLayout from "./GatePassLayout";
import { db, storage } from "../firebase"; // Import the Firestore instance
import Logo from "./gatepass-logo.png";
import jsPDF from "jspdf";
import { Link } from "react-router-dom";

function GatePassForm() {
  const generatePDFButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [gpNumber, setGPNumber] = useState("");

  const [formInputs, setFormInputs] = useState({
    partyName: "",
    GPNo: "", // Use GPNo from state
    date: "",
    gpcomment: "",
    items: [
      {
        boxnumber: "",
        itemName: "",
        packingStyle: "",
        quantity: "",
        rate: "",
        gst: "",
      },
    ],
  });

  useEffect(() => {
    // Set up a real-time listener for GP number
    const unsubscribe = db
      .collection("auto-gpnumber")
      .doc("doc-gpnumber")
      .onSnapshot((doc) => {
        if (doc.exists) {
          const currentGPNo = doc.data().gpnumber;
          setGPNumber(currentGPNo);
          setFormInputs((prevState) => ({
            ...prevState,
            GPNo: currentGPNo,
          }));
        } else {
          console.log("No such document!");
        }
      });

    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormInputs({ ...formInputs, [name]: value });
  };

  const resetFormInputs = () => {
    setFormInputs({
      partyName: "",
      GPNo: gpNumber, // Keep the current GP number
      date: "",
      gpcomment: "",
      salesChannel: "", 
      items: [
        { boxnumber: "", itemName: "", packingStyle: "", quantity: "", rate: "", gst: "" },
      ],
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Increment GP number locally before updating in Firestore
    const updatedGPNo = parseInt(formInputs.GPNo) + 1;
    // Update GP No. in Firestore
    try {
      await db.collection("gatePasses").add({
        partyName: formInputs.partyName,
        // pinCode: formInputs.pinCode,
        salesChannel: formInputs.salesChannel,
        GPNo: formInputs.GPNo,
        date: new Date(),
        items: formInputs.items,
        pdfName: `${formInputs.GPNo}-${formInputs.partyName}-GatePass.pdf`,
        DeliveryStatus: "NotDelivered",
        comment: formInputs.gpcomment,
        // Add other data as needed
      });

      await db.collection("auto-gpnumber").doc("doc-gpnumber").update({
        gpnumber: updatedGPNo,
      });

      console.log("Data stored in Firestore");
    } catch (error) {
      console.error("Error storing data in Firestore:", error);
    }

    // Generate PDF
    await generatePDF();
    setLoading(false);
    // setGPNumber(updatedGPNo);
    window.location.reload();
  };

  const generatePDF = async () => {
    const pdf = new jsPDF({
      orientation: "landscape",
    });
    // pdf.text("Gate Pass Form", 15, 15);
    // Add more content to the PDF as needed based on your layout
    pdf.autoTable({
      html: "#gatePassTablelayout",
      theme: "grid",
      useCss: true,
    });
    // Save PDF to Firebase Storage
    const pdfBlob = pdf.output("blob");
    const storageRef = storage.ref();
    const pdfFileName = `${formInputs.GPNo}-${formInputs.partyName}-GatePass.pdf`;
    const pdfFileRef = storageRef.child(pdfFileName);

    try {
      // Upload the PDF blob to Firebase Storage
      await pdfFileRef.put(pdfBlob);
      console.log("PDF uploaded to Firebase Storage");

      // Get the download URL
      const downloadURL = await pdfFileRef.getDownloadURL();

      // Open the PDF in a new tab
      window.open(downloadURL, "_blank");

      // Reset form inputs after PDF is uploaded
      resetFormInputs();
    } catch (error) {
      console.error("Error uploading PDF to Firebase Storage:", error);
    }
  };

  const handleItemChange = (index, e) => {
    const updatedItems = formInputs.items.map((item, i) => {
      if (i === index) {
        return { ...item, [e.target.name]: e.target.value };
      }
      return item;
    });
    setFormInputs({ ...formInputs, items: updatedItems });
  };

  const addItem = () => {
    setFormInputs({
      ...formInputs,
      items: [
        ...formInputs.items,
        {
          boxnumber: "",
          itemName: "",
          packingStyle: "",
          quantity: "",
          rate: "",
          gst: "",
          gpComment: "",
        },
      ],
    });
  };

  const removeItem = (index) => {
    const updatedItems = [...formInputs.items];
    updatedItems.splice(index, 1);
    setFormInputs({ ...formInputs, items: updatedItems });
  };

  return (
    <Container>
      <div className="text-center">
        <img src={Logo} alt="logo" className="logo my-5" />
      </div>
      <Row>
        <Col>
          <div className="my-3 gatepassform p-3">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formPartyName">
                <Form.Label>Party Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter party name"
                  name="partyName"
                  value={formInputs.partyName}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              {/* <Form.Group controlId="formpinCode">
                <Form.Label>Address Pin Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Address Pin Code"
                  name="pinCode"
                  value={formInputs.pinCode}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group> */}

              <Form.Group controlId="formsalesChannel" className="mt-2">
                <Form.Label>Sales Channel</Form.Label>
                <Form.Control
                  as="select"
                  name="salesChannel"
                  value={formInputs.salesChannel}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Sales Channel</option>
                  <option value="Offline">Offline</option>
                  <option value="Indiamart">Indiamart</option>
                  <option value="Factory">Factory</option>
                  <option value="Retail">Retail</option>
                  <option value="Courier">Courier</option>
                  <option value="Sample">Sample</option>
                  <option value="Others">Others</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formGPNo" className="mt-2">
                <Form.Label>GP No.</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter GP No."
                  name="GPNo"
                  value={gpNumber}
                  readOnly
                />
              </Form.Group>
              <Form.Group controlId="gpcomment" className="mt-2">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Comment"
                  name="gpcomment"
                  value={formInputs.gpcomment}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Table striped responsive bordered className="mt-2">
                <thead>
                  <tr>
                    <td style={{ minWidth: "12rem" }}>Box No.</td>
                    <td style={{ minWidth: "12rem" }}>Item Name</td>
                    <td style={{ minWidth: "12rem" }}>Packing Style</td>
                    <td style={{ minWidth: "6rem" }}>Quantity</td>
                    <td style={{ minWidth: "6rem" }}>Rate</td>
                    <td style={{ minWidth: "6rem" }}>GST</td>
                    <td style={{ minWidth: "6rem" }}>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {formInputs.items.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Enter Box Number"
                          name="boxnumber"
                          value={item.boxnumber}
                          onChange={(e) => handleItemChange(index, e)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Enter item name"
                          name="itemName"
                          required
                          value={item.itemName}
                          onChange={(e) => handleItemChange(index, e)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Enter packing style"
                          name="packingStyle"
                          value={item.packingStyle}
                          onChange={(e) => handleItemChange(index, e)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Enter quantity"
                          name="quantity"
                          value={item.quantity}
                          onChange={(e) => handleItemChange(index, e)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Enter rate"
                          name="rate"
                          value={item.rate}
                          onChange={(e) => handleItemChange(index, e)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Enter GST"
                          name="gst"
                          value={item.gst}
                          onChange={(e) => handleItemChange(index, e)}
                        />
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          onClick={() => removeItem(index)}
                          className="rounded-pill"
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="mx-auto text-center p-2">
                <Button
                  variant="primary"
                  onClick={addItem}
                  className="m-3 rounded-pill"
                >
                  <i className="fa fa-plus mr-2"></i> Add Item
                </Button>

                <Button
                  variant="success"
                  type="submit"
                  className="ml-2 rounded-pill"
                >
                  {loading ? (
                    <div
                      className="spinner-border text-white downloading-spinner"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <i className="fa fa-cloud-download me-2"></i>
                  )}
                  Download Gatepass
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>

      <div className="text-center mb-3 pb-3">
        <Link to="/gatepasslist">
          <button className="btn btn-primary rounded">
            <i className="fa fa-wpforms me-2"></i>
            Gate Pass List
          </button>
        </Link>
        <a href="https://script.google.com/macros/s/AKfycbxfvrGlViyMAaBIcMciUV3WBew2LpTv0-TFoMwaInb51hrYSuTGE7ovH6RkOTv4hVQc5g/exec" className="btn btn-primary rounded mx-2 me-2"  target="_blank" >
  <i className="fa fa-wpforms me-2"></i>
  Sales Report
</a>

      </div>
      {/* <div className="my">
        <div
          className="alert alert-warning alert-dismissible fade show alertinfo"
          role="alert"
        >
          <strong>UPDATE!</strong> Download Packing Slip is now working for all
          PDF
        </div>
      </div> */}
      <div className="d-none">
        <GatePassLayout formData={formInputs} ref={generatePDFButtonRef} />
      </div>
    </Container>
  );
}

export default GatePassForm;
